
html,
body ,#root{
    height: 100% !important;
    font-family: 'Poppins', sans-serif;
}
@keyframes soundanimation {
    0%{       
      
      transform: scale(0.9);
    }
    50%{
        opacity:1;
        transform: scale(1);
        
    }
    100%{        
        transform: scale(0.9);
    }
}
*{
    scrollbar-width: 0px;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}
*::-webkit-scrollbar{
    width: 0px;
    display: none;
}